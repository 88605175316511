import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { checkWebPSupport } from "supports-webp-sync";
import { motion } from "framer-motion";
import { useIsMobile } from "../hooks";

import { SUPPORTED_REGIONS } from "../../shared/enums";

import heroImage1 from "assets/images/hero-black-friday/hero-black-friday-img-01.jpg";
import heroImageWebp1 from "assets/images/hero-black-friday/hero-black-friday-img-01.jpg?as=webp";
import heroImage2 from "assets/images/hero-black-friday/hero-black-friday-img-02.jpg";
import heroImageWebp2 from "assets/images/hero-black-friday/hero-black-friday-img-02.jpg?as=webp";

import heroImageSm1 from "assets/images/hero-black-friday/hero-black-friday-img-sm-01.jpg";
import heroImageWebpSm1 from "assets/images/hero-black-friday/hero-black-friday-img-sm-01.jpg?as=webp";
import heroImageSm2 from "assets/images/hero-black-friday/hero-black-friday-img-sm-02.jpg";
import heroImageWebpSm2 from "assets/images/hero-black-friday/hero-black-friday-img-sm-02.jpg?as=webp";

const slideData = [
  {
    id: 1,
    className: "",
    content: {
      leadText: "Now in 2 sizes",
      leadTextSm: null,
      title: "Honey, we shrunk the Mila",
      titleSm: (
        <>
          Honey, we shrunk
          <br /> the Mila
        </>
      ),
      description:
        "Let Mila Air Mini tackle the air in your smaller spaces—so no room gets left behind.",
      descriptionSm: null,
    },

    img: {
      desktop: heroImage1,
      webp: heroImageWebp1,
      sm: heroImageSm1,
      webpSm: heroImageWebpSm1,
    },

    button: {
      className: "button button--shadow-dark button--lg",
      text: "Learn more",
      textSm: null,
      link: "https://buy.milacares.com/",
    },
    offerTitle: null,
    badge: {
      className: null,
      text: (
        <>
          Save up to <strong>37%</strong>
        </>
      ),
    },
    offer: {
      text: {
        US: "Black Friday launch offer starting at just $169.",
        CA: "Black Friday launch offer starting at just $241.",
      },
    },
  },
  {
    id: 2,
    className: "hero-black__item--alt hero-black__item--dark",

    content: {
      leadText: "BLACK FRIDAY EARLY ACCESS",
      leadTextSm: <>BLACK FRIDAY EARLY ACCESS</>,
      title: "Stock up on clean air.",
      titleSm: "Black Friday Early Access",
      description: {
        US: (
          <>
            Save up to 43% on Mila Air — the award-winning air purifier that
            packs a boatload of performance into a tiny footprint.
          </>
        ),

        GB: "Save up to 43% on the air purifier that packs a boatload of performance into a tiny footprint.",
        AU: "Save up to 43% on the air purifier that packs a boatload of performance into a tiny footprint.",
        SG: "Save up to 43% on the air purifier that packs a boatload of performance into a tiny footprint.",
        CA: "Save up to 43% on the air purifier that packs a boatload of performance into a tiny footprint.",
      },
      descriptionSm: null,
    },
    img: {
      desktop: heroImage2,
      webp: heroImageWebp2,
      sm: heroImageSm2,
      webpSm: heroImageWebpSm2,
    },
    button: {
      className: "button button--white button--lg",
      text: "Save Now",
      textSm: null,
      link: "https://milacares.com/buy",
    },
    offerTitle: (
      <>
        black <br />
        friday <br />
        sale
      </>
    ),
    badge: {
      className: "hero-black__badge--top-left",
      text: (
        <>
          Save up to <strong>43%</strong>
        </>
      ),
    },
    offer: {
      text: "Black Friday launch offer starting at just $241.",
    },
  },
];

/**
 * @returns {JSX.Element}
 * @constructor
 */

const HeroBlackFriday = ({ localization }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;

  const regionalBlackFriday = [
    SUPPORTED_REGIONS.GB,
    SUPPORTED_REGIONS.AU,
    SUPPORTED_REGIONS.SG,
  ].includes(SUPPORTED_REGIONS[region]);

  const isMobile = useIsMobile(769);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [progress, setProgress] = useState(-100);
  const [currentSlide, setCurrentSlide] = useState(0);
  const isWebPSupported = checkWebPSupport();

  const handleBeforeChange = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    if (!isSliderInitialized) return;
    setProgress(-100);
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 0 ? -100 : prev + 1));
    }, 60);
    return () => clearInterval(interval);
  }, [isSliderInitialized, currentSlide]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: "0",
        },
      },
    ],
    onInit: () => {
      setIsSliderInitialized(true);
    },
    beforeChange: (_, nextIdx) => {
      handleBeforeChange(nextIdx);
    },
  };

  return (
    <>
      <section
        className="hero-black"
        style={{ "--progress": `${progress}%` }}
      >
        <div className="hero-black__container">
          <Slider className="hero-black__slick" {...settings}>
            {slideData
              .filter((slide, index) => index !== 0 || !regionalBlackFriday)
              .map((slide, index) => (
                <>
                  {slide.id === 1 ? (
                    <div className={`hero-black__item`} key={slide.id}>
                      <span
                        className="hero-black__item-bg"
                        style={{
                          backgroundImage: `url(${
                            isWebPSupported
                              ? isMobile
                                ? slide.img.webpSm
                                : slide.img.webp
                              : isMobile
                              ? slide.img.sm
                              : slide.img.desktop
                          })`,
                        }}
                      >
                        {slide.badge && (
                          <p
                            className={`hero-black__badge ${slide.badge.className}`}
                          >
                            <span>{slide.badge.text}</span>
                          </p>
                        )}
                      </span>
                      <div className="hero-black__item-content">
                        <div className="hero-black__item-content-inner">
                          <strong className="hero-black__leadtext">
                            {isMobile
                              ? slide.content.leadTextSm ??
                                slide.content.leadText
                              : slide.content.leadText}
                          </strong>
                          <h2 className="hero-black__title">
                            {isMobile
                              ? slide.content.titleSm ?? slide.content.title
                              : slide.content.title}
                          </h2>
                          <div className="hero-black__description">
                            <p>
                              {isMobile
                                ? slide.content.descriptionSm ??
                                  slide.content.description
                                : slide.content.description}
                            </p>
                            <p className="hero-black__offer">
                              {slide.offer.text[regional]}
                            </p>
                          </div>
                          <a
                            href={slide.button.link}
                            className={`button ${slide.button.className} ${
                              isMobile ? "button--white" : "button--black"
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {isMobile
                              ? slide.button.textSm ?? slide.button.text
                              : slide.button.text}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`hero-black__item ${slide.className}`}
                      key={slide.id}
                    >
                      <div className="hero-black__item-grid">
                        <div className="hero-black__item-content">
                          <div className="hero-black__item-content-inner">
                            <motion.strong
                              className="hero-black__leadtext"
                              initial={{ opacity: 0, y: 20 }}
                              whileInView="visible"
                              viewport={{ once: true }}
                              transition={{ duration: 0.6, delay: 0 }}
                              variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 20 },
                              }}
                            >
                              {isMobile
                                ? slide.content.leadTextSm ??
                                  slide.content.leadText
                                : slide.content.leadText}
                            </motion.strong>
                            <motion.h2
                              className="hero-black__title"
                              initial={{ opacity: 0, y: 20 }}
                              whileInView="visible"
                              viewport={{ once: true }}
                              transition={{ duration: 0.6, delay: 0.01 }}
                              variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 20 },
                              }}
                            >
                              {isMobile
                                ? slide.content.titleSm ?? slide.content.title
                                : slide.content.title}
                            </motion.h2>
                            <motion.div
                              className="hero-black__description"
                              initial={{ opacity: 0, y: 20 }}
                              whileInView="visible"
                              viewport={{ once: true }}
                              transition={{ duration: 0.6, delay: 0.02 }}
                              variants={{
                                visible: { opacity: 1, y: 0 },
                                hidden: { opacity: 0, y: 20 },
                              }}
                            >
                              <p>
                                {isMobile
                                  ? slide.content.descriptionSm ??
                                    slide.content.description[regional]
                                  : slide.content.description[regional]}
                              </p>
                            </motion.div>
                            <a
                              href={slide.button.link}
                              className={`button ${slide.button.className}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {isMobile
                                ? slide.button.textSm ?? slide.button.text
                                : slide.button.text}
                            </a>
                          </div>
                        </div>
                        <div
                          className="hero-black__item-media"
                          style={{
                            backgroundImage: `url(${
                              isWebPSupported
                                ? isMobile
                                  ? slide.img.webpSm
                                  : slide.img.webp
                                : isMobile
                                ? slide.img.sm
                                : slide.img.desktop
                            })`,
                          }}
                        >
                          {slide.offerTitle && (
                            <h2 className="hero-black__badge-title">
                              {slide.offerTitle}
                            </h2>
                          )}
                          {slide.badge && (
                            <p
                              className={`hero-black__badge ${slide.badge.className}`}
                            >
                              <span>{slide.badge.text}</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
          </Slider>
        </div>
      </section>
      <div className="price-table">
        <div className="container">
          <div className="price-table__footer">
            <h3 className="price-table__title">The Mila Promise</h3>
            <ul className="price-table__list">
              <li>
                <svg className="icon icon-money-back-guarantee">
                  <use xlinkHref="#icon-money-back-guarantee" />
                </svg>
                30 day Mila-backed guarantee
              </li>
              <li>
                <svg className="icon icon-shipping">
                  <use xlinkHref="#icon-shipping" />
                </svg>
                Fast free shipping with same/next business day shipping
              </li>
              <li>
                <svg className="icon icon-warranty">
                  <use xlinkHref="#icon-warranty" />
                </svg>
                Two year warranty
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBlackFriday;
